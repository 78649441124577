import React from "react";
import NavBar from "./components/NavBar";
import MapPage from "./Pages/MapPage";
import { Routes, Route } from "react-router-dom";
import PricingPage from "./Pages/PricingPage";
import AboutPage from "./Pages/AboutPage";
import ContactPage from "./Pages/ContactPage";
import LoginPage from "./Pages/LoginPage";
import Footer from "./components/Footer";
import CardPage from "./Pages/CardPage";
// import logo from './logo.svg';
// import './App.css';

function App() {
  return (
    <div>
      <NavBar />
      <div>
        <Routes>
          <Route path="/" element={<MapPage />} />
          <Route path="/home" element={<MapPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/inventory" element={<CardPage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
