import React from "react";
import SupabaseFetchTest from "../components/SupabaseFetchTest";

const CardPage = () => {
  return (
    <div>
      <SupabaseFetchTest />
    </div>
  );
};

export default CardPage;
