import React, { useEffect, useState } from "react";
import supabase from "../config/supabaseClient";
import BillboardCard from "./BillboardCard";

const SupabaseFetchTest = () => {
  const [fetchError, setFetchError] = useState(null);
  const [billboards, setBillboards] = useState(null);

  useEffect(() => {
    const fetchBillboards = async () => {
      const { data, error } = await supabase.from("Billboards").select();

      if (error) {
        setFetchError("Could not fetch billboards");
        setBillboards(null);
        console.log(error);
      }
      if (data) {
        setBillboards(data);
        setFetchError(null);
        console.log("Data was fetched");
      }
    };
    fetchBillboards();
  }, []);

  return (
    <div>
      {fetchError && <p>{fetchError}</p>}
      {billboards && (
        <p>
          <div>
            {billboards.map((billboard) => (
              <BillboardCard key={billboard.id} billboard={billboard} />
            ))}
          </div>
        </p>
      )}
    </div>
  );
};

export default SupabaseFetchTest;
