import React from "react";

const BillboardCard = ({ billboard }) => {
  return (
    <div className="bg-gray-800 w-[350px] rounded-xl hover:bg-gray-700 hover:cursor-pointer *:p-3 m-5">
      <div className="font-semibold text-2xl text-white">{billboard.title}</div>
      <div className="text-lg text-white">{billboard.price}</div>
      <div className="text-lg text-white">{billboard.type}</div>
    </div>
  );
};

export default BillboardCard;
