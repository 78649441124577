import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { Link } from "react-router-dom";

//import Locator from "./Map"; *ADD react-router-dom to add navigation*

const NavBar = () => {
  const [nav, setNav] = useState(true);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className="text-white flex items-center h-21 max-w-[1240px] mx-auto p-3 justify-between">
      <div>
        <Link to="/home" className="flex flex-row items-center cursor-pointer">
          <img
            src="favicon2.svg"
            alt="Streetmark Logo"
            style={{ height: 30, paddingRight: 10 }}
          />
          <h1 className="w-full text-3xl font-bold text-[#00df9a]">
            StreetMark
          </h1>
        </Link>
      </div>
      <ul className="hidden *:px-4 *:py-2 md:flex *:cursor-pointer *:transition-colors *:duration-300">
        <Link to="/home" className="hover:text-[#00df9a]">
          Home
        </Link>
        <Link to="/about" className="hover:text-[#00df9a]">
          About
        </Link>
        <Link to="/inventory" className="hover:text-[#00df9a]">
          Inventory
        </Link>
        <Link to="/pricing" className="hover:text-[#00df9a]">
          Pricing
        </Link>
        <Link to="/login" className="hover:text-[#00df9a]">
          Login
        </Link>
        <Link
          to="/contact"
          className="bg-white text-black rounded-md hover:bg-[#00df9a]"
        >
          Contact
        </Link>
      </ul>
      <div onClick={handleNav} className="block md:hidden">
        {!nav ? (
          <AiOutlineClose size={20} className="hover:cursor-pointer" />
        ) : (
          <AiOutlineMenu size={20} className="hover:cursor-pointer" />
        )}
      </div>
      <div
        className={
          !nav
            ? "fixed z-10 left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#323232] ease-in-out duration-500"
            : "fixed left-[-100%]"
        }
      >
        <h1 className="w-full text-3xl font-bold text-[#00df9a] m-4">
          StreetMark
        </h1>

        <ul className="flex flex-col uppercase p-4 *:p-4 *:hover:cursor-pointer *:transition-colors *:duration-250">
          <Link
            to="/home"
            onClick={handleNav}
            className="border-b border-gray-600 hover:text-[#00df9a]"
          >
            Home
          </Link>
          <Link
            to="/about"
            onClick={handleNav}
            className="border-b border-gray-600 hover:text-[#00df9a]"
          >
            About
          </Link>
          <Link
            to="/inventory"
            onClick={handleNav}
            className="border-b border-gray-600 hover:text-[#00df9a]"
          >
            Inventory
          </Link>
          <Link
            to="/pricing"
            onClick={handleNav}
            className="border-b border-gray-600 hover:text-[#00df9a]"
          >
            Pricing
          </Link>
          <Link
            to="/login"
            onClick={handleNav}
            className="border-b border-gray-600 hover:text-[#00df9a]"
          >
            Login
          </Link>
          <Link
            to="/contact"
            onClick={handleNav}
            className="hover:text-[#00df9a]"
          >
            Contact
          </Link>
        </ul>
      </div>
    </div>
  );
};

export default NavBar;
